import { Injectable, inject } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
    #cockieservices = inject(CookieService);
    constructor() { }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let request = req;

        if (!request.url.includes('/auth') && !request.url.includes('/registroadmin')) {
            request = req.clone({
                setHeaders: {
                    'x-api-key': this.#cockieservices.get('token')
                }
            });
        }


        return next.handle(request);
    }
}
