import { Routes } from '@angular/router';
// layouts
import { AppLayout } from './layouts/app-layout';
import { AvanceModule } from './pages/avance/avance.module';



export const routes: Routes = [

    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((d) => d.AuthModule)
    },
    {
        path: 'dashboard',
        component: AppLayout,
        children: [
            // dashboard

            { path: 'users/admin', loadChildren: () => import('./pages/user/admin/admin.module').then((d) => d.AdminModule) },
            { path: 'users/usuarios', loadChildren: () => import('./pages/user/usuario/user.module').then((d) => d.UserModule) },
            { path: 'mayoristas', loadChildren: () => import('./pages/mayoristas/mayoristas.module').then((d) => d.MayoristasModule) },
            { path: 'cedis', loadChildren: () => import('./pages/cedis/cedis.module').then((d) => d.CedisModule) },
            { path: 'planes', loadChildren: () => import('./pages/planes/planes.module').then((d) => d.PlanesModule) },
            { path: 'zonas', loadChildren: () => import('./pages/zonas/zonas.module').then((d) => d.ZonasModule) },
            { path: 'rutas', loadChildren: () => import('./pages/rutas/rutas.module').then((d) => d.RutasModule) },
            { path: 'perfiles', loadChildren: () => import('./pages/perfiles/perfiles.module').then((d) => d.PerfilesModule) },
            { path: 'clientes/marcas', loadChildren: () => import('./pages/clientes/marcas/marcas.module').then((d) => d.MarcasModule) },
            { path: 'clientes/submarcas', loadChildren: () => import('./pages/clientes/submarcas/submarcas.module').then((d) => d.SubmarcasModule) },
            { path: 'clientes/usuarios', loadChildren: () => import('./pages/clientes/usuarios-clientes/usuarios-clientes.module').then((d) => d.UsuariosClientesModule) },
            { path: 'metricas', loadChildren: () => import('./pages/metricas/metricas.module').then((d) => d.MetricasModule) },
            { path: 'avance', loadChildren: () => import('./pages/avance/avance.module').then((d) => d.AvanceModule) },

        ]

    },


    {
        path: '**',
        redirectTo: 'auth',
        pathMatch: 'full'
    }
];
